<template>
  <div id="employmentView">
    <SimpleContentBlock
      v-if="simpleContentData"
      :simple-content-data="simpleContentData"
    />
    <EmploymentForm />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import helpers from '@/api/helpers/helpers'
import api from '@/api'
import SimpleContentBlock from '@/components/UI/SimpleContentBlock'
import EmploymentForm from '@/components/Forms/EmploymentForm'

export default {
  name: 'Employment',
  components: { EmploymentForm, SimpleContentBlock },
  data() {
    return {
      simpleContentData: null,
      loaded: false,
    }
  },
  mounted() {
    this.loadEmploymentView()
  },
  methods: {
    ...mapMutations({
      setPageLoadingFalse: 'setPageLoadingFalse',
    }),
    loadEmploymentView() {
      this.setEmploymentData(helpers.readStoredResponse('view.employment'))

      api.views.loadEmployment().then((response) => {
        this.setEmploymentData(response)
      })
    },
    setEmploymentData(response) {
      if (!response || !response.entry) return

      const entry = response.entry

      this.simpleContentData = entry.simpleContentBlock[0] || null

      if (!this.loaded) {
        setTimeout(() => {
          this.setPageLoadingFalse()
        }, 500)
        this.loaded = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#employmentView {
  @apply bg-black;
  margin-bottom: 200px;
  padding-top: 60px;
  padding-bottom: 60px;
  min-height: 700px;
}
</style>
