<template>
  <div class="employment-form-container">
    <FormSuccess v-if="submitted" class="container" />
    <form
      v-else
      action="#"
      @submit.prevent="submitEmploymentForm"
      enctype="multipart/form-data"
      id="employmentForm"
      ref="employmentForm"
    >
      <FormError :error="error" />

      <input type="hidden" name="action" value="contact-form/send" />
      <input type="hidden" name="subject" value="Employment Form Submission" />

      <div class="field-group">
        <div class="field">
          <label for="fromName">Name<span>*</span></label>
          <input
            type="text"
            id="fromName"
            name="fromName"
            v-model="fields.name"
            required
          />
        </div>

        <div class="field">
          <label for="phoneNumber">Phone</label>
          <input
            type="tel"
            id="phoneNumber"
            name="message[phoneNumber]"
            v-model="fields.phoneNumber"
          />
        </div>
      </div>

      <div class="field-group">
        <div class="field">
          <label for="fromEmail">Email<span>*</span></label>
          <input
            type="email"
            id="fromEmail"
            name="fromEmail"
            v-model="fields.email"
            required
          />
        </div>
      </div>

      <div class="field-group">
        <div class="field">
          <label for="resume">Resume<span>*</span></label>
          <input type="file" id="resume" name="attachment" required />
        </div>
      </div>

      <div class="field-group">
        <div class="field">
          <label for="message">Message<span>*</span></label>
          <textarea
            id="message"
            name="message[body]"
            v-model="fields.message"
            rows="4"
            required
          ></textarea>
        </div>
      </div>

      <div class="field-group">
        <div class="field">
          <button v-if="submitting" disabled>
            <FontAwesomeIcon icon="spinner" spin />
            SUBMITTING
          </button>
          <button v-else>SUBMIT</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import FormSuccess from '@/components/Forms/FormSuccess'
import FormError from '@/components/Forms/FormError'
export default {
  name: 'EmploymentForm',
  components: { FormError, FormSuccess },
  data() {
    return {
      fields: {
        name: '',
        email: '',
        phoneNumber: '',
        message: '',
      },
      submitting: false,
      submitted: false,
      error: '',
    }
  },
  methods: {
    submitEmploymentForm() {
      if (this.submitting) return

      this.submitting = true
      this.error = ''

      let form = this.$refs.employmentForm
      let formData

      formData = new FormData(form)
      if (window.Craft) {
        formData.append(window.Craft.csrfTokenName, window.Craft.csrfTokenValue)
      }

      this.$axios
        .post('/', formData)
        .then(() => {
          this.submitted = true
        })
        .catch((error) => {
          const response = error.response

          if (response && response.data && response.data.error) {
            this.error = response.data.error
          } else {
            this.error =
              'Something went wrong while trying to submit the form. Please try again.'
          }
        })
        .finally(() => {
          this.submitting = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.employment-form-container {
  @apply mt-10;

  form {
    @apply mx-auto;
    max-width: 600px;

    .field-group {
      @apply flex flex-wrap;

      .field {
        @apply w-full px-4 py-2;

        label {
          @apply block text-left pb-1;
          font-size: 18px;
          color: #cfcfcf;

          span {
            color: #d76b00;
          }
        }

        input,
        textarea {
          @apply block w-full px-2 py-1;
          border: 1px solid #cfcfcf;
          background-color: #4f4f4f;
          color: #cfcfcf;
          border-radius: 12px;

          &:focus {
            border: 1px solid #d76b00;
            outline: none;
          }
        }

        button {
          @apply px-2 py-1;
          border: 1px solid #cfcfcf;
          background-color: #f17800;
          color: #ffffff;
          text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
          border-radius: 12px;
          transition: background-color 0.3s ease-in;

          &:hover {
            background-color: #be5f00;
          }
        }
      }
    }
  }

  @media screen and (min-width: 600px) {
    form {
      .field-group {
        @apply flex-nowrap;
      }
    }
  }
}
</style>
